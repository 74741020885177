// In your component
const Headline = () => {
    return (
        <h1 style={styles.title}>
            What do you put on your <span style={styles.highlight}>SKIIN</span>?
        </h1>
    );
};

const styles = {
    title: {
        marginTop: '100px',
        fontSize: '3rem',
        color: '#5ac8de'
    },
    highlight: {
        color: '#ff007a',
    },
};

export default Headline;