import logo from './logo.svg';
import Header from './Header';
import './App.css';
import React, {useState} from "react";
import Headline from "./Headline";
import axios from 'axios';
import search from './search.svg';
import {Route, Routes} from "react-router-dom";
import Contact from "./Contact";
import About from "./About";

const Home = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [query, setQuery] = useState('');
    const formattedIngredients = formatIngredients(query);
    let ingredients = []
    const handleChange = (event) => {
        setQuery(event.target.value);
    };
    const handleButtonClick = () => {
        ingredients = formattedIngredients
        searchField();
    };
    const searchField = async () => {
        try {
            const response = await axios.post('http://localhost:3001/api/data', ingredients, {
                headers: {'Content-Type': 'application/json'}
            });
            if (Array.isArray(response.data)) {
                response.data.forEach((item, index) => {
                    console.log(`Item ${index}: ${item}`);
                    alert(`Response Data: ${response.data.map(item => item.name).join(', ')}`);
                });
            } else {
                console.error('The response is not an array:', response);
            }
        } catch (error) {
            if (error.response) {
                console.error('Server responded with:', error.response.status, error.response.data);
            }
        }
    };
    return (
        <div className="App">
            <div>
                <Header logo={logo} title="SKIIN"/>

                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap"
                    rel="stylesheet"/>
                <hr style={styles.line}/>
                <Headline className="centered"/>
                <input className="centered"
                       type="text"
                       value={query}
                       onChange={handleChange}
                       placeholder="Add ingredients..."
                       style={styles.input}/>
                <img src={search} alt="Search" onClick={handleButtonClick} style={styles.runbutton}/>

                <Routes>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </div>
        </div>
    );
}

function formatIngredients(ingredientsString) {
    const ingredientsArray = ingredientsString.split(',');
    return ingredientsArray.map(ingredient => {
        return ingredient.trim().replace(/^'(.*)'$/, '$1');
    });
}

const styles = {
    line: {
        border: 'none',
        borderTop: '1px solid #8adced',
        margin: '0 0px',
    },
    input: {
        width: '50%',
        padding: '30px',
        fontSize: '1rem',
        border: '1px solid #8adced',
        borderRadius: '30px',
        outline: 'none',
        color: '#5ac8de'
    },
    runbutton: {
        width: '50px',
        height: '50px',
        background: '#fff',
        cursor: 'pointer',
        marginBottom: '-20px',
        marginLeft: '-70px'
    }
}

export default Home;
