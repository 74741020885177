// Header.js
import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({logo, title}) => {

    const menuItems = ["Home", "About", "Contact"];

    return (
        <header style={styles.header}>
            <div style={styles.headerContainer}>
                <img src={logo} style={styles.logo}/>
                <h1>{title}</h1>
                <nav style={styles.menuContainer}>
                    {menuItems.map((item, index) => (
                        <Link key={index} to={`/${item.toLowerCase()}`} style={styles.menuItem}>
                            {item}
                        </Link>
                    ))}
                </nav>
            </div>
        </header>
    );
};

const styles = {
    header: {
        backgroundColor: '#ffffff',
        color: '#5ac8de',
        paddingLeft: '50px',
        textAlign: 'start',
        minHeight: '70px'
    },
    headerContainer: {
        display: 'flex', // this to make it one next to another
        alignItems: 'center',
        gap: '10px',
    },
    logo: {
        display: 'flex',
        width: '50px',
        height: '50px',
        objectFit: 'contain',
    },
    menuContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '15px',
        marginLeft: 'auto',
        marginRight: '50px'
    },
    menuItem: {
        fontSize: '1rem',
        color: '#5ac8de',
        textDecoration: 'none',
        fontWeight: 'bold',
        padding: '0px 20px',
        transition: 'background-color 0.3s',
    }
};

export default Header;
